// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'

import { FaExternalLinkSquareAlt, FaPhone } from 'react-icons/fa'
import { MdPlace, MdNotInterested, MdVerifiedUser, MdEmail } from 'react-icons/md'

import SocialLinks from '../components/socialLinks'
import Tags from '../components/tags'
import Button from '../components/button'
import Products from "./blocks/products"
import Customers from "./blocks/customers"
import Values from "./blocks/values"
import Milestones from "./blocks/milestones"
import Team from "./blocks/team"
import Promos from "./blocks/promos"
import Quote from "./blocks/quote"
import Video from "./blocks/video"
import Gallery from "./blocks/gallery"

const SingleListing = (props) => {
    const {
        color,
        logo, 
        entreprise,
        name, 
        url,
        pitch,
        locations,
        telephone,
        email,
        blocks,
        cover,
        verified,
        tags,
    } = props

    // console.log("BLOCKS", blocks)

    return (
        <div>
            <div
                sx={{
                    mt: 5, mb: 4,
                    borderRadius: 2, bg: 'background',
                    borderTop: cover ? 'none' : `10px solid ${color || 'white'}`,
                    boxShadow: 0, overflow: 'hidden',
                }}
            >

                {cover && <Img fluid={cover} />}

                <div sx={{
                    p: 4, 
                }}>
                
                    <Flex 
                        sx={{
                            justifyContent: 'space-between', alignItems: 'flex-start',
                        }}
                    >
                        <Box>
                            {logo &&
                            <Img 
                                fixed={logo} 
                                sx={{
                                    borderRadius: 1, border: 0,
                                }}
                            />
                            }
                        </Box>

                        <Box>
                            <SocialLinks data={entreprise} />
                        </Box>
                    </Flex>

                    <h1 sx={{m: 0, pt: 2}}>
                        {name}
                    </h1>

                    {url && <a href={url} target="_blank"
                        sx={{
                            color: 'grey', bg: 'muted',
                            py: 2, px: 3, mt: 3,
                            display: 'inline-block',
                            borderRadius: 0,                     
                            textDecoration: 'none',
                        }}
                    >
                        {url.replace(/https?:\/\/(www\.)?/, "").split('/')[0]}
                    </a>}

                    {verified && <span sx={{
                        fontSize: 2,
                        color: 'white', bg: 'limegreen',
                        py: 2, px: 3, mt: 3, ml: 3,
                        display: 'inline-block',
                        borderRadius: 0, 
                    }}>
                        <MdVerifiedUser sx={{mb: '-2px'}}/> Membre vérifié
                    </span>}

                    {tags &&
                        <div sx={{
                            mt: 3,
                        }}>
                            <Tags tags={tags} color={color} />
                        </div>
                    }

                    <p>{pitch}</p>

                    {locations[0] && locations[0].name && <Flex
                        sx={{
                            flexWrap: 'wrap',
                            my: 4,
                        }}
                    >
                        {/* JSON.stringify(locations) */}
                        {locations.map( (location, index) => (
                            <Box
                                key={location.name + '-' + index}
                                sx={{
                                    border: 0, borderRadius: 0, borderColor: color,
                                    p: 3, mr: 2, mb: 2, minWidth: '300px',
                                }}
                            >
                                <h3 sx={{m:0, fontSize: 2, svg: {mb: '-2px'}}}><MdPlace sx={{color: color}} /> {location.name || name}</h3>
                                <div sx={{mt:1}} dangerouslySetInnerHTML={{ __html: location.address.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                            </Box>
                        ))}
                    </Flex>}

                    <Flex
                        sx={{ 
                            flexWrap: 'wrap',
                            gap: '10px',
                        }}
                    >
                        {url && <Button link={url} 
                            title={`Aller sur le site internet de ${name}`} blank="true" color={color}
                            className="card-button" data-button="page--external-url" data-uid={url}
                        >
                            <FaExternalLinkSquareAlt /> Voir Site Web
                        </Button>}

                        {telephone && <Button link={`tel:${telephone}`} 
                            title={`Appeler par téléphone ${name}`} color={color}
                            className="card-button" data-uid={url} data-button="contact--phone"
                        >
                            <FaPhone /> {telephone.substr(0, 8)}...
                        </Button>}

                        {email && <Button link={`mailto:${email}`} 
                            title={`Ecrire un email à ${name}`} color={color}
                            className="card-button" data-uid={url} data-button="contact--email"
                        >
                            <MdEmail /> Contacter par email
                        </Button>}
                    </Flex>

                    <div sx={{
                        fontSize: 0, color: 'grey',
                        mt: 3, mb: 0,
                    }}>
                        <MdNotInterested sx={{color: 'red', mb: '-2px'}}/> Cet établissement ne souhaite pas recevoir de publicité.
                    </div>
                </div>

            </div>

            {blocks.map( block => {

                // console.log("Block", block)

                if ( block.slice_type === 'products' ) return (
                    <Products 
                        key={block.id}
                        intro_text={block.primary.intro.text}
                        color={color}
                        products={block.items.map( product => ({
                            name: product.name,
                            description: product.description.text,
                            image: product.image.fluid,
                            link_title: product.link_title,
                            link_url: product.link.url,
                        }))}
                    />
                )

                if ( block.slice_type === 'customers' ) return (
                    <Customers 
                        key={block.id}
                        intro_text={block.primary.intro.text}
                        color={color}
                        customer_types={block.items.map( customer => ({
                            name: customer.name,
                            color: customer.color,
                            jobs: customer.jobs.text,
                            pains: customer.pains.text,
                            gains: customer.gains.text,
                        }))}
                    />
                )

                if ( block.slice_type === 'values' ) return (
                    <Values
                        key={block.id}
                        title={block.primary.replace_title}
                        intro_text={block.primary.intro.text}
                        cover={block.primary.cover.fluid}
                        values={block.items.map( value => ({
                            name: value.name,
                            description: value.description.text
                        }))}
                        color={color}
                    />
                )

                if ( block.slice_type === 'milestones' ) return (
                    <Milestones
                        key={block.id}
                        events={block.items.map( event => ({
                            name: event.date,
                            description: event.event_name
                        }))}
                        color={color}
                    />
                )

                if ( block.slice_type === 'team' ) return (
                    <Team
                        key={block.id}
                        members={block.items.map( member => ({
                            name: member.name,
                            role: member.function,
                            picture: member.profile_picture.fixed,
                            bio: member.bio.html,
                            cv_link: member.cv_link.url,
                        }))}
                        color={color}
                    />
                )

                if ( block.slice_type === 'promospace' ) return (
                    <Promos
                        key={block.id}
                        promos={block.items.map( promo => ({
                            header: promo.header,
                            deadline: promo.deadline,
                            description: promo.description.text,
                            cta_text: promo.cta_text,
                            cta_link: promo.link.url,
                            image: promo.image.fixed,
                        }))}
                        color={color}
                    />
                )

                if ( block.slice_type === 'quote' ) return (
                    <Quote
                        key={block.id}
                        author={block.primary.author}
                        author_title={block.primary.author_title}
                        quote={block.primary.text.text}
                        color={color}
                    />
                )

                if ( block.slice_type === 'video' ) return (
                    <Video
                        key={block.id}
                        code={block.primary.embed.html}
                        caption={block.primary.caption}
                        color={color}
                    />
                )

                if ( block.slice_type === 'gallery' ) return (
                    <Gallery
                        key={block.id}
                        items={block.items}
                    />
                )

                return null
            })}
        </div>
    )
}

export default SingleListing