// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

const Values = (props) => {
    const {
        title,
        intro_text,
        values,
        color,
        cover,
    } = props

    return (
        <div sx={{ 
            mb: 4, borderRadius: 2, 
            bg: 'background',
            overflow: 'hidden',
            boxShadow: 0,
        }}>
            {cover && <Img fluid={cover} />}
            <div sx={{
                p: 4, 
            }}>
                <h2 sx={{mt: 0}}>{title ? title : "Nos valeurs"}</h2>
                <p>{intro_text}</p>
                <Grid
                    gap={5}
                    columns={[ 1, 2, 3 ]}
                >
                    {values.map( (value, index) => {
                        const {
                            name,
                            description
                        } = value

                        return (
                            <div 
                                key={name}
                                sx={{
                                    position: 'relative',
                            }}>
                                <h3 sx={{
                                    mb: 2,
                                }}>
                                    {name}
                                </h3>
                                <span sx={{
                                    position: 'absolute',
                                    top: "-10px", left: "-20px",
                                    fontSize: 7, color: color, opacity: '.2', fontWeight: 'bold', lineHeight: 1,
                                }}>{index+1}</span>

                                <p sx={{
                                    fontSize: 1, mt: 0, mb: 1,
                                }}>
                                    {description}
                                </p>
                            </div>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}

export default Values